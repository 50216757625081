import betterTrucksLogo from "../src/betterTrucksLogo.png";
import { BeatLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import "./App.css";
import Moment from "moment";
import React, { useState } from "react";

// 1. import `ChakraProvider` component
import { ChakraProvider, Center, useToast } from "@chakra-ui/react";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorValue, setErrorValue] = useState("");
  const [shipmentValue, setShipmentValue] = useState("");
  const [shipmentResult, setShipmentResult] = useState(null);

  const toast = useToast();

  function handleSubmit(e) {
    e.preventDefault();

    if (shipmentValue === "") {
      setShipmentValue("");
      setShipmentResult(null);
      setErrorValue("Please enter a shipment number");

      toast({
        title: "Error",
        description: "Please, provide a valid shipment number",
        status: "warning",
        position: "top-right",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    setErrorValue("");

    // GET request using axios with set headers
    const headers = {
      "X-API-KEY": "IRT9-UQE3M-6ACMD3",
    };

    setIsLoading(true);

    axios
      .get(process.env.REACT_APP_API_GET_SHIPMENT + "/" + shipmentValue, {
        headers,
      })
      .then((response) => {
        console.log(response.data);
        setShipmentResult(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(`GET ${process.env.REACT_APP_API_GET_SHIPMENT + "/" + shipmentValue} (ERROR):`, error);
        console.log(`GET ${process.env.REACT_APP_API_GET_SHIPMENT + "/" + shipmentValue} (ERROR.RESPONSE): `, error.response);
        setShipmentResult(null);
        setIsLoading(false);
        if (error.response.status === 404) {
          toast({
            title: "Error",
            description: `Shipment ${shipmentValue} not found`,
            status: "error",
            position: "top-right",
            duration: 4000,
            isClosable: true,
          });

        } else {
          toast({
            title: "Error",
            description: "Please, provide a valid shipment number",
            status: "error",
            position: "top-right",
            duration: 4000,
            isClosable: true,
          });
        }
      });
  }

  function handleAddOrphaneLabelData(e) {
    e.preventDefault();

    // POST request using axios with set headers
    setIsLoading(true);

    const data = shipmentValue.split(",").map((val) => val.trim());
    axios
      .post(process.env.REACT_APP_API_POST_SHIPMENT, data, {
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": "IRT9-UQE3M-6ACMD3",
        },
      })
      .then((response) => {
        console.log("API_POST_SHIPMENT (SUCCESS) - ", response.data);

        setIsLoading(false);
        setShipmentValue("");
        setErrorValue("");

        toast({
          title: "Success",
          description: "Shipment added successfully",
          status: "error",
          position: "top-right",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((error) => {

        console.log('--------------------------------------');
        console.log(`POST ${process.env.REACT_APP_API_POST_SHIPMENT} (ERROR):`, error);
        console.log(`POST ${process.env.REACT_APP_API_POST_SHIPMENT} (ERROR.RESPONSE): `, error.response);
        console.log('--------------------------------------');

        setIsLoading(false);

        if (error.response.data) {
          setErrorValue(error.response.data);
        } else {
          setErrorValue("Something went wrong");
        }

        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          position: "top-right",
          duration: 4000,
          isClosable: true,
        });
      });
  }

  function formatDate(_dateTime) {
    const formatDate = Moment(_dateTime).format("MM-DD-YYYY, hh:mm:ss");
    return formatDate;
  }

  return (
    <ChakraProvider>
      <div className="App">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="jumbotron text-center">
                <Center bg="" h="100px" color="white">
                  <img
                    src={betterTrucksLogo}
                    className="App-logo"
                    alt="logo"
                    style={{ alignSelf: "center" }}
                    width={120}
                  />
                </Center>

                <h3>BetterTrucks Client Application</h3>
                <p className="lead">
                  To look up for a shipment, please type the number and click
                  the verify button
                </p>

                <div className="row spinnerContainer">
                  <LoadingOverlay
                    active={isLoading}
                    spinner={<BeatLoader />}
                  ></LoadingOverlay>
                </div>

                <div className="row">
                  <div className="col-md-4 offset-md-4">
                    <form onSubmit={handleSubmit}>
                      <p>
                        <input
                          id="shipmentValue"
                          name="shipmentValue"
                          className="form-control"
                          type="text"
                          onChange={(event) =>
                            setShipmentValue(event.target.value)
                          }
                          value={shipmentValue}
                          placeholder="Enter shipment number"
                        />
                      </p>

                      {errorValue != "" && (
                        <div className="col-md-12">
                          <p className="text-danger">{errorValue}</p>
                        </div>
                      )}

                      <p className="lead">
                        <button
                          className="btn btn-primary btn-lg"
                          type="submit"
                        >
                          Verify
                        </button>
                      </p>
                    </form>
                  </div>
                </div>
                <div className="row">
                  {shipmentResult != null && (
                    <div className="col-md-6 offset-md-3">
                      <hr className="my-4" />

                      <table className="table">
                        <tbody>
                          <tr>
                            <th scope="row">Tracking number</th>
                            <td>{shipmentResult.shipment_tracking_code}</td>
                          </tr>
                          <tr>
                            <th scope="row">Shipment date</th>
                            <td>{formatDate(shipmentResult.shipment_date)}</td>
                          </tr>
                          <tr>
                            <th scope="row">Is voided?</th>
                            <td>{shipmentResult.is_voided ? "Yes" : "No"}</td>
                          </tr>
                          <tr>
                            <th scope="row">ETA</th>
                            <td>{formatDate(shipmentResult.eta)}</td>
                          </tr>
                          <tr>
                            <th scope="row">Shipment Created Date</th>
                            <td>{formatDate(shipmentResult.object_created)}</td>
                          </tr>
                          <tr>
                            <th scope="row">Shipment Id</th>
                            <td>{shipmentResult.object_id}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div className="col-md-6 offset-md-3">
                    <p className="lead">
                      <button
                        className="btn btn-success btn-md"
                        onClick={handleAddOrphaneLabelData}
                      >
                        Add Shipment(s)
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ChakraProvider>
  );
}
export default App;
